<template>
  <v-dialog
    v-if="matchingShopItems.length"
    v-model="dialog.show"
    v-bind="dialog.bind"
    v-on="dialog.events"
  >
    <v-card rounded="lg" elevation="0">
      <v-card-title v-if="display.mdAndDown.value" class="py-0">
        <div class="d-flex justify-end">
          <v-btn icon @click="dialog.events['click:outside']">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="matchingShopItems.length" class="pt-6">
        <div>
          <Asset v-if="image" :blok="{ image }" rounded />
          <h4>
            {{
              $t(
                `default.page_left.dialog.title${display.mdAndDown.value ? "_mobile" : ""}`,
              )
            }}
          </h4>
          <div class="d-flex gap-2 flex-column">
            <template v-if="registrationResponse?.status !== 'pending'">
              <div>
                {{
                  matchingShopItems[0].content.page_left_text ||
                  matchingShopItems[0].content.teaser
                }}
              </div>
            </template>

            <div v-if="!downloadAccess" class="flex-grow-1">
              <div v-if="registrationResponse?.status === 'pending'">
                {{ $t("mailchimp.verify_subscription_before_download.text") }}
              </div>
              <div v-else class="mt-4">
                <VTextField
                  v-model="email"
                  name="email"
                  required
                  hide-details="auto"
                  density="compact"
                  :error-messages="
                    error ? $t('default.email.error.invalid') : null
                  "
                  prepend-inner-icon="mdi mdi-email"
                  label="E-Mail"
                  @update:model-value="error = false"
                />

                <div class="button pa-0 mt-4">
                  <v-btn
                    variant="text"
                    :ripple="false"
                    block
                    :disabled="loading"
                    :loading
                    @click="submitEmail"
                  >
                    {{ $t("default.submit.label") }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-else class="mt-6" role="list">
              <div class="mb-2">
                <strong>
                  {{ $t("shop.download.info.text") }}
                </strong>
              </div>
              <div
                v-for="(download, i) in downloads"
                :key="i"
                class="download-item"
              >
                <div v-if="download.filename" class="button-wrap">
                  <NuxtLink
                    :to="download.filename"
                    target="_blank"
                    class="button mt-2 w-button"
                  >
                    {{ $t("shop.download.btn.label") }}
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();

const { cookie, dialog, watchPageLeave } = useMyPage({ display });
const props = defineProps({
  story: Object,
});

const story = props.story;
const matchingShopItems = ref([]);
const prefixes = ["blog", "podcast", "whitepaper", "news", "lexikon"];
const hasValidPrefix = prefixes.some((prefix) =>
  story?.default_full_slug.startsWith(prefix),
);

const image = computed(
  () =>
    matchingShopItems.value?.[0]?.content?.page_left_image ||
    matchingShopItems.value?.[0]?.content?.images?.[0],
);

const { downloadAccess, downloads, handleFreeDownload } = useShop();
const email = ref("");
const error = ref(false);

function shopItemTagMatch(item) {
  return story.content?.whitepaper_tags?.includes(item.content.tag);
}

if (hasValidPrefix) {
  const shopItems = await useShop().items();

  // Filtere die Shop-Artikel basierend auf Übereinstimmungen mit story.tag_list
  // Jetzt enthält matchingItems alle Shop-Artikel, die mit mindestens einem Tag aus story.tag_list übereinstimmen.
  matchingShopItems.value = shopItems
    .filter(
      (item) =>
        shopItemTagMatch(item) ||
        item.tag_list.some((tag) => story.tag_list.includes(tag)),
    )
    .sort((a, b) => shopItemTagMatch(b) - shopItemTagMatch(a));

  if (matchingShopItems.value.length > 0) {
    watchPageLeave(() => {
      // Set cookie to redirect to the current page after newsletter registration
      const tokenRedirectCookie = useCookie("zweikern_token_redirect", {
        expires: new Date(new Date().setDate(new Date().getDate() + 1)),
      });
      tokenRedirectCookie.value = matchingShopItems.value?.[0]?.full_slug;
    });
  }
}
const loading = ref(false);
const registrationResponse = ref(null);
async function submitEmail() {
  if (email.value !== "") {
    loading.value = true;
    const response = await handleFreeDownload(email.value, {
      story: matchingShopItems.value[0],
    });
    registrationResponse.value = response?.data;
    if (response?.data?.status !== "pending") {
      cookie.set("subscribed", { expires: 365 });
    }
    loading.value = false;
  } else {
    error.value = true;
  }
}
</script>
