<script setup>
const { $store, $router } = useNuxtApp();
const { slug } = useRoute().params;
const config = useRuntimeConfig();
const storyblokStore = useStoryblokStore();
const storyblokApiOptions = useStoryblokApiOptions();
const storyblokApi = useStoryblokApi();
const { locale } = useI18n();
const resolveRelations = [];

let url = slug && slug.length > 0 ? slug.join("/") : "home";

// urlWithoutLangPath
url = url.replace(/\/$/, "");

const story = ref(null);

if (!url.includes("m-edia/") && !url.includes("trash/")) {
  try {
    const { data } = await storyblokApi.get(`cdn/stories/${url}`, {
      ...storyblokApiOptions,
      language: locale.value,
    });

    story.value = data.story;

    if (
      locale.value !== "de" &&
      story.value &&
      !story.value.translated_slugs?.find((slug) => slug.lang === locale.value)
        ?.name
    ) {
      throw createError({
        statusCode: 404,
        statusMessage: "Page Not Found",
        fatal: true,
      });
    }

    if (!story.value) {
      let isTranslatedSlug = false;

      Object.keys(storyblokStore.links).find((key) => {
        const link = storyblokStore.links[key];

        if (
          link.alternates.find((alternate) => alternate.translated_slug === url)
        ) {
          isTranslatedSlug = true;
          url = link.slug;
          return true;
        }
        if (isTranslatedSlug) {
          return true;
        }
      });

      // Find the story.value with the translated slug as fallback
      if (isTranslatedSlug) {
        story.value = await useAsyncStoryblok(
          url,
          {
            ...config.public.storyblok.apiOptions,
            language: locale.value,
            resolve_relations: resolveRelations,
          },
          {
            resolveRelations,
          },
        );
      }

      story.value = unref(story.value);
    }

    // Bugfix: redirect https://zweikern.com/ to correct language if story.value is not found
    if (!story.value && locale.value !== "de") {
      $router.push(`${locale.value}/home`);
    }

    if (story.value?.status) {
      throw createError({
        statusCode: story.value.status,
        statusMessage: story.value.response,
        fatal: true,
      });
    }

    if (story.value) {
      let defaultStory = null;
      if (locale.value !== "de") {
        defaultStory = await useAsyncStoryblok(
          url,
          {
            ...config.public.storyblok.apiOptions,
            language: "de",
            resolve_relations: resolveRelations,
          },
          {
            resolveRelations,
          },
        );
      }

      $store.dispatch("setStory", story.value);
      story.value.content.og_image = story.value.content?.og_image?.filename
        ? story.value.content?.og_image
        : story.value.content?.image;

      useMyHead(story.value, defaultStory?.value);
    }
  } catch (error) {
    if (typeof error === "string") {
      const e = destr(error);
      throw createError({
        statusCode: e?.status || 500,
        statusMessage: e?.response,
      });
    }

    throw createError({
      statusCode: error?.status || 500,
      statusMessage: error?.response,
    });
  }
}

// if (!story.value) {
//   showError({ statusCode: 404, statusMessage: "Page Not Found" });
// }

const animateCSSElements = (prefix = "animate__") => {
  // Get all elements with data-animate attribute
  const nodes = document.querySelectorAll("[data-animate]");

  nodes.forEach((node) => {
    const animation = node.getAttribute("data-animate");
    const delay = node.getAttribute("data-animate-delay") || 0; // Default delay is 0
    const duration = node.getAttribute("data-animate-duration") || 8; // Default delay is 0
    const elementVisible = node.getAttribute("data-animate-visible") || 50; // Default delay is 150
    const repeat = node.getAttribute("data-animate-repeat") || 0; // Default delay is 0

    const windowHeight = window.innerHeight;
    const elementTop = node.getBoundingClientRect().top;

    // Add the animation classes directly
    const animationName = `${prefix}${animation}`;

    // Initially hide the element
    if (!node.classList.contains("animate__animated")) {
      node.classList.add("hidden");
    }

    if (elementTop < windowHeight - elementVisible) {
      node.classList.remove("hidden");
      node.classList.add("animated", `${prefix}animated`, animationName);

      if (delay > 0) {
        node.classList.add(`d${delay}`);
      }
      if (duration > 0) {
        node.classList.add(`a${duration}`);
      }
    } else {
    }

    // When the animation ends, we clean the classes
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(
        `${prefix}animated`,
        animationName,
        `d${delay}`,
        `a${duration}`,
      );
    }

    if (repeat > 0) {
      node.addEventListener("animationend", handleAnimationEnd, { once: true });
    }
  });
};

const { replaceHeadlineGradient } = useUtils();
// let initAnimateCSSElementsInterval = null;
// onBeforeMount(() => {
//   initAnimateCSSElementsInterval = setInterval(
//     () => initAnimateCSSElements(),
//     100,
//   );
// });
const hidden = ref(true);

if ($router.currentRoute.value.query?.session_id) {
  useShoppingCart().clear();
}

onMounted(async () => {
  replaceHeadlineGradient();

  // Fix for the headline gradient not showing up on page load
  setTimeout(() => {
    replaceHeadlineGradient();
  }, 1000);

  hidden.value = false;
  animateCSSElements();
  window.addEventListener("scroll", () => animateCSSElements());
});

onUnmounted(() => {
  replaceHeadlineGradient();
  animateCSSElements();
  window.removeEventListener("scroll", () => animateCSSElements());
});
</script>

<template>
  <div>
    <BgHomeGradient v-if="story?.content?.layout === 'hero'" />

    <StoryblokComponent
      v-if="story?.content"
      :blok="story.content"
      :story="story"
    />

    <OptInPopup v-if="story && locale === 'de'" :story />
    <!-- <ScrollTop v-if="story.value" :show="showScrollToTopButton" /> -->
  </div>
</template>
